//overwriting the understrap class
.wrapper {
  padding: 0;
}

// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
body {
  font-family: 'Poppins', sans-serif;

  //font-family: 'Lato', sans-serif;
  //overflow-x: hidden;
}

//new font variable

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1rem;
}

.small-font {
  font-size: 0.813rem;
}

.large-font {
  font-size: 2.5rem;
}

.xl-large-font {
  font-size: 4.625rem;
}


//new color variable
.metro-top-gradient {
  background: linear-gradient(90deg, rgba(97, 4, 95, 1) 35%, rgba(170, 7, 107, 1) 100%);
}

.metro-text-dark-color {
  color: $metro-text-dark;
}

.metro-title-color {
  color: $metro-title;
}

.metro-primary-color,.read_more {
  color: $metro-primary;
}
.read_more:hover {
  color: $metro-primary;
}

.metro-footer-bg {
  background-color: $metro-footer-bg;
}

.line-height {
  line-height: 1.5rem;
}

.entry-content {
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    //font-weight: 500;
  }

  h2 {
    color: $metro-title !important;
    font-size: 2rem !important;
    font-weight: 800 !important;
    margin: 2rem 0 1.3rem 0 !important;
  }

  h3 {
    color: $metro-title;
    font-size: 2.125rem;
    font-weight: 800;
    margin: 2rem 0 1.3rem 0;
  }

  h4 {
    color: $metro-title !important;
    font-size: 1.625rem;
    font-weight: 800;
    margin: 1.4rem 0 1.3rem 0;
  }

  ul {
    li {
      font-weight: 500;
      line-height: 1.5;
      padding-bottom: 1rem;
    }
  }
}

.service-bg {
  background-color: $metro-service-bg;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media(min-width: 992px) {
    margin: 4rem 0;
  }
  .card-title{
    color: black;
  }
  h3{
    margin: 0;
  }
}

// google review css
.google-banner{
  padding-top: 2rem;
  padding-bottom: 1rem;
}

// youtube section
.youtube-main {
  border: solid 0.4rem transparent;
  border-radius: 0.5rem;
  background-image: linear-gradient(#000000, #000000),
  linear-gradient(0deg, rgba(111, 39, 115, 1) 0%, rgba(20, 186, 200, 1) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  margin-top: 3rem;
}

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: .4s all;
  -moz-transition: .4s all;
  transition: .4s all;
}

.youtube-player img:hover {
  -webkit-filter: brightness(75%);
}

.youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  background: url("#{$img-path}/youtube.png") no-repeat;
  cursor: pointer;
}

// about us license css
.license-number {
  padding-bottom: 6rem;
  color: $metro-text-light;
  @media (min-width: 992px) {
    padding-bottom: 8rem;
  }

  strong {
    font-size: 1rem;
    padding-right: 1rem;
    color: $metro-text-light;
    line-height: 1.8;
  }
}
.margin-top{
  margin-top: 8rem;
}

//frontend admin bar
#wpadminbar:after {
  content: " ";
  position: absolute;
  display: block;
  bottom: -5px;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg,#63055B 0,#830266 35%,#A6056C 100%);
}
/*#wpadminbar a {
  color: #5FC9BD !important;
}
#wpadminbar a:hover {
  color: #0C63F0 !important;
}*/
#wpadminbar {
  background-image: url("#{$img-path}/hero-bg-img.jpg") !important;
  background-position: center;
}
#wpadminbar .ab-top-menu > li.hover > .ab-item,
#wpadminbar.nojq .quicklinks .ab-top-menu > li > .ab-item:focus,
#wpadminbar:not(.mobile) .ab-top-menu > li:hover > .ab-item,
#wpadminbar:not(.mobile) .ab-top-menu > li > .ab-item:focus {
  opacity: 0.75;
}

//alert
.alert {
  background-color: #1c1c1c;
  font-size: .875rem
}

.close {
  font-size: 2.5rem;
  line-height: 0.7;
  color: #fff !important;
  font-weight: 100;
}

.alert a:hover {
  color: unset
}

.close:hover {
  color: #fff !important;
  text-decoration: none;
}