@font-face {
	font-family:  Poppins;
	src:          url("#{$font-path}/Poppins-SemiBold.eot");
	src:          local("Poppins SemiBold"),
	              local("Poppins-SemiBold"),
	              url("#{$font-path}/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
	              url("#{$font-path}/Poppins-SemiBold.woff2") format("woff2"),
	              url("#{$font-path}/Poppins-SemiBold.woff") format("woff"),
	              url("#{$font-path}/Poppins-SemiBold.ttf") format("truetype"),
	              url("#{$font-path}/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
	font-weight:  600;
	font-style:   normal;
	font-display: swap;
	unicode-range: U+000-5FF;
}

@font-face {
	font-family:  Poppins;
	src:          url("#{$font-path}/Poppins-Bold.eot");
	src:          local("Poppins Bold"),
	              local("Poppins-Bold"),
	              url("#{$font-path}/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
	              url("#{$font-path}/Poppins-Bold.woff2") format("woff2"),
	              url("#{$font-path}/Poppins-Bold.woff") format("woff"),
	              url("#{$font-path}/Poppins-Bold.ttf") format("truetype"),
	              url("#{$font-path}/Poppins-Bold.svg#Poppins-Bold") format("svg");
	font-weight:  700;
	font-style:   normal;
	font-display: swap;
	unicode-range: U+000-5FF;
}

@font-face {
	font-family:  Poppins;
	src:          url("#{$font-path}/Poppins-Regular.eot");
	src:          local("Poppins Regular"),
	              local("Poppins-Regular"),
	              url("#{$font-path}/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
	              url("#{$font-path}/Poppins-Regular.woff2") format("woff2"),
	              url("#{$font-path}/Poppins-Regular.woff") format("woff"),
	              url("#{$font-path}/Poppins-Regular.ttf") format("truetype"),
	              url("#{$font-path}/Poppins-Regular.svg#Poppins-Regular") format("svg");
	font-weight:  400;
	font-style:   normal;
	font-display: swap;
	unicode-range: U+000-5FF;
}

@font-face {
	font-family:  Poppins;
	src:          url("#{$font-path}/Poppins-Light.eot");
	src:          local("Poppins Light"),
	              local("Poppins-Light"),
	              url("#{$font-path}/Poppins-Light.eot?#iefix") format("embedded-opentype"),
	              url("#{$font-path}/Poppins-Light.woff2") format("woff2"),
	              url("#{$font-path}/Poppins-Light.woff") format("woff"),
	              url("#{$font-path}/Poppins-Light.ttf") format("truetype"),
	              url("#{$font-path}/Poppins-Light.svg#Poppins-Light") format("svg");
	font-weight:  300;
	font-style:   normal;
	font-display: swap;
	unicode-range: U+000-5FF;
}