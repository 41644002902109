.blank-section {
	min-height: 2rem;
	@media(min-width: 576px) {
		min-height: 5rem;
	}
	@media(min-width: 768px) {
		min-height: 6rem;
	}
	@media(min-width: 992px) {
		min-height: 8rem;
	}
}

.custom-banner {
	background-repeat:     no-repeat;
	background-attachment: fixed;
	background-position:   center;
	background-size:       cover;
	
	.col-12 {
		margin-top:    -2rem;
		margin-bottom: -2rem;
		min-height:    200px;
	}
	
	h4 {
		font-size:   1.75rem;
		font-weight: 700;
		color:       $metro-title;
		@media(min-width: 576px) {
			font-size: 1.75rem;
		}
		@media(min-width: 992px) {
			font-size: 2rem;
		}
	}
	
	p {
		font-size: 1rem;
		color:     $metro-text-dark;
		padding:   1rem 0;
	}
	
	.custom-bookcall {
		.btn {
			border-radius: 0.3rem;
			padding:       0.75rem 3rem;
			font-size:     1rem;
		}
	}
	
	.first-col {
		text-align:       center;
		background-image: linear-gradient(#202F4C, #0A101C);
		@media(min-width: 576px) {
			box-shadow: 0 -1px 3px 0 #363636;
		}
		@media(min-width: 768px) {
			box-shadow: -1px 0 3px 0 #363636;
		}
		@media(min-width: 992px) {
			position: relative;
		}
	}
	
	.second-col {
		background-color: #FFF;
		box-shadow:       0 1px 3px 0 #363636;
		padding:          3rem;
		text-align:       center;
		@media(min-width: 768px) {
			box-shadow: 1px 0 3px 0 #363636;
			text-align: left;
		}
		@media(min-width: 992px) {
			padding: 5rem 3rem;
		}
		@media(min-width: 1200px) {
			padding: 5rem;
		}
		@media(max-width: 768px) {
			margin-top: -4rem !important;
		}
		
		h2 {
			font-weight: 700;
		}
		
		p {
			color: $metro-text-dark;
		}
	}
	
	.banner-human {
		width:  50%;
		height: auto;
		@media(min-width: 768px) {
			position:  absolute;
			width:     150%;
			max-width: none;
			bottom:    0;
			right:     -2rem;
			z-index:   1;
		}
		@media(min-width: 992px) {
			width: 100%;
		}
		@media(min-width: 1200px) {
			width: 90%;
		}
		@media(max-width: 992px) {
			margin-top: -3rem;
		}
	}
	
	.btn {
		border-radius: 50px;
	}
}

.call-section {
	.container-left-half {
		//these padding and margin are to reflect original bootstrap container
		//padding-right: 15px;
		//padding-left: 15px;
		margin-right: auto;
		margin-left:  auto;
		width:        100%;
		@media (min-width: 576px) {
			//max-width for container at this width is 540px
			max-width: calc(540px - 30px);
		}
		@media (min-width: 768px) {
			margin-right: 0; //so the box aligns left;
			//max-width for container at this width is 720px
			max-width:    calc(360px - 30px);
		}
		@media (min-width: 992px) {
			//max-width for container at this width is 960px
			max-width: calc(480px - 30px);
		}
		@media (min-width: 1200px) {
			//max-width for container at this width is 1140px
			max-width: calc(570px - 30px);
		}
	}
	
	.bg-gradiant {
		background:     linear-gradient(90deg, rgba(99, 5, 91, 1) 0%, rgba(131, 2, 102, 1) 35%, rgba(166, 5, 108, 1) 100%);
		padding-top:    5rem;
		padding-bottom: 5rem;
		
		h3 {
			color:       $white;
			font-size:   2rem;
			font-weight: 800;
		}
		
		p {
			color:          $white;
			padding-top:    2rem;
			padding-bottom: 2rem;
		}
	}
	
	.bg-image {
		background-position: top center;
		background-size:     cover;
		background-repeat:   no-repeat;
		min-height:          250px;
	}
	
}