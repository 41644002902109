.pagination {
	display:    inline-flex;
	margin-top: 3rem;
	list-style: none;
	
	.page-item {
		.page-link {
			border-radius: 50px;
			height:        37px;
			width:         38px;
      position: relative;
      display: block;
      padding: .5rem .75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: $secondary;
      background-color: $white;
      border: 1px solid $light-grey;
		}
    
    &.active .page-link{
      z-index: 1;
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
	}
}

.btn.loadMoreBtn {
	text-align:       center;
	background-color: #01C906;
	margin-top:       2rem;
	color:            $white !important;
	font-size:        1rem;
	font-weight:      700;
}

.btn.loadMoreBtn:hover {
	text-decoration: none;
}