.location-section {
	background-repeat:   no-repeat;
	background-position: top center;
	background-size:     cover;
	margin-bottom:       6rem;
	padding-bottom:      3rem;
	
	.text-wrapper {
		padding: 0.5rem 0 1rem;
		@media(min-width: 768px) {
			padding: 3rem 0 1rem;
		}
		@media(min-width: 992px) {
			padding: 6rem 0 0;
		}
		@media(min-width: 1200px) {
			padding: 9rem 0 3rem;
		}
		
		.text-title {
			font-size:     1.75rem;
			font-weight:   bolder;
			color:         $metro-title;
			margin-bottom: 1rem;
			@media(min-width: 1200px) {
				font-size: 2rem;
			}
		}
		
		.text-description {
			font-size: 1rem;
			color:     $metro-text-dark;
			
			a {
				color:       $metro-primary;
				font-weight: 500;
			}
		}
		
		.text-subtitle {
			font-size:   1.15rem;
			font-weight: bold;
			color:       $metro-learn-more;
			margin-top:  2rem;
			@media(min-width: 1200px) {
				font-size: 1.5rem;
			}
		}
		
		.ajaxsearchpro {
			margin: 0 !important;
		}
		
	}
	
	.oz-map {
		position: relative;
		width:    100%;
		height:   auto;
	}
	
	.location-markers {
		.ping-wrapper {
			position: absolute;
			
			.ring {
				width:                             25px;
				height:                            25px;
				border-radius:                     50%;
				position:                          absolute;
				-webkit-animation-name:            ping;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-timing-function: linear;
				-webkit-animation-direction:       normal;
				-webkit-animation-duration:        3s;
			}
			
			@-webkit-keyframes ping {
				0% {
					-webkit-transform: scale(0);
				}
				99% {
					-webkit-transform: scale(1);
				}
				100% {
					-webkit-transform: scale(1);
					opacity:           0
				}
			}
			
			.r1 {
				-webkit-animation-delay: -1s;
				//background-color:        #A0F8F8;
				background-color:        #FFC600;
			}
			
			.r2 {
				-webkit-animation-delay: -2s;
				//background-color:        #40F2F2;
				background-color:        #FFC600;
			}
			
			.r3 {
				-webkit-animation-delay: -3s;
				//background-color:        #0DBEBF;
				background-color:        #FFC600;
			}
			
			&:hover {
				.r1 {
					background-color: #FF33B4;
				}
				
				.r2 {
					background-color: #FF00A2;
				}
				
				.r3 {
					background-color: #CC0081;
				}
			}
		}
		
		#perth { top: 55%; left: 10%; }
		
		#adelaide { top: 63%; left: 57%; }
		
		#brisbane { top: 43%; right: 14%; }
		
		#goldcoast { top: 46%; right: 13%; }
		
		#sydney { top: 61%; right: 18%; }
		
		#canberra { top: 65%; right: 22%; }
		
		#melbourne { top: 73%; right: 30%; }
		
		#geelong { top: 74%; right: 32%; }
		
		#bendigo { top: 69%; right: 32%; }
		
		#ballarat { top: 71%; right: 33%; }
		
		@media(min-width: 375px) {
			#perth { top: 55%; left: 10%; }
			#adelaide { top: 65%; left: 58%; }
			#brisbane { top: 43%; right: 12%; }
			#goldcoast { top: 46%; right: 11%; }
			#sydney { top: 61%; right: 16%; }
			#canberra { top: 65%; right: 21%; }
			#melbourne { top: 74%; right: 29%; }
			#geelong { top: 75%; right: 31%; }
			#bendigo { top: 70%; right: 31%; }
			#ballarat { top: 72%; right: 32%; }
		}
		@media(min-width: 435px) {
			#perth { top: 38vw; left: 8vw; }
			#adelaide { top: 46vw; left: 48vw; }
			#brisbane { top: 34vw; right: 9vw; }
			#goldcoast { top: 36vw; right: 8.5vw; }
			#sydney { top: 44vw; right: 13vw; }
			#canberra { top: 47vw; right: 17vw; }
			#melbourne { top: 52vw; right: 24vw; }
			#geelong { top: 53vw; right: 25vw; }
			#bendigo { top: 49vw; right: 26vw; }
			#ballarat { top: 51vw; right: 27vw; }
		}
		@media(min-width: 576px) {
			#perth { top: 58%; left: 10%; }
			#adelaide { top: 66%; left: 59%; }
			#brisbane { top: 45%; right: 9%; }
			#goldcoast { top: 47%; right: 8.5%; }
			#sydney { top: 65%; right: 14%; }
			#canberra { top: 69%; right: 19%; }
			#melbourne { top: 76%; right: 27%; }
			#geelong { top: 77%; right: 28%; }
			#bendigo { top: 72%; right: 29%; }
			#ballarat { top: 75%; right: 30%; }
		}
		@media(min-width: 768px) {
			//#perth { top: 58%; left: 10%; }
			#adelaide { top: 67%; left: 59%; }
			#brisbane { top: 45%; right: 9%; }
			#goldcoast { top: 47%; right: 8%; }
			#sydney { top: 69%; right: 15%; }
			#canberra { top: 72%; right: 19%; }
			#melbourne { top: 76%; right: 27%; }
			#geelong { top: 77%; right: 28%; }
			#bendigo { top: 73%; right: 28%; }
			#ballarat { top: 76%; right: 30%; }
		}
		@media(min-width: 992px) {
			#perth { top: 53%; left: 10%; }
			#adelaide { top: 62%; left: 59%; }
			#brisbane { top: 44%; right: 9%; }
			#goldcoast { top: 46%; right: 8.5%; }
			#sydney { top: 63%; right: 15%; }
			#canberra { top: 66%; right: 18%; }
			#melbourne { top: 69%; right: 26.5%; }
			#geelong { top: 71%; right: 27.5%; }
			#bendigo { top: 68%; right: 28%; }
			#ballarat { top: 71%; right: 30%; }
		}
		@media(min-width: 1200px) {
			//#perth { top: 53%; left: 10%; }
			#adelaide { top: 61%; left: 60%; }
			#brisbane { top: 46%; right: 8%; }
			#goldcoast { top: 48%; right: 7.5%; }
			#sydney { top: 61%; right: 13%; }
			//#canberra { top: 65%; right: 18%; }
			//#melbourne { top: 69%; right: 26%; }
			//#geelong { top: 70%; right: 28%; }
			//#bendigo { top: 67%; right: 28%; }
			//#ballarat { top: 69%; right: 29%; }
		}
	}
	
}