.service-banner {
	background-color: $light-grey;
	@media(min-width: 992px) {
		background-position: left center;
		background-repeat:   no-repeat;
		background-size:     45% auto;
	}
	@media(min-width: 1200px) {
		//background-size: 33% auto;
		background-image: none !important;
	}
	@media(max-width: 991px) {
		background-image: none !important;
	}
	
	.service-text {
		margin: 2rem 0;
		@media(min-width: 576px) {
			margin: 4rem 0;
		}
		@media(min-width: 1200px) {
			margin: 8rem 0;
		}
		
		.service-title {
			font-size:   1.75rem;
			font-weight: bolder;
			@media(min-width: 768px) {
				font-size: 2rem;
			}
		}
		
		p {
			color:       $metro-text-dark;
			margin:      1rem 0 1.5rem;
			line-height: 1.6;
		}
	}
}