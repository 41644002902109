.location-near-me {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 5rem;
  @media (min-width: 996px) {
    margin: 6rem 0;
  }
  
  .location-near-me-right {
    border-radius: 0.5rem;
    margin: 5rem 0;
    
    .location-near-me-title {
      padding-top: 2rem;
      font-size: 2rem;
      font-weight: normal;
      color: $metro-title;
      @media (min-width: 996px) {
        font-size: 3rem;
        padding-top: 0;
      }
    }
    
    .location-near-me-sub_title {
      font-size: 2rem;
      font-weight: bold;
      color: $metro-learn-more;
      @media (min-width: 996px) {
        font-size: 3rem;
      }
    }
    
    .location-near-me-bottom {
      color: $metro-text-dark;
      padding-bottom: 2rem;
      @media (min-width: 996px) {
        padding-bottom: 0;
      }
      
      a {
        color: $metro-primary;
        text-decoration: none;
        font-weight: 500;
      }
    }
    
    #ajaxsearchpro1_1 {
      border: 2px solid #a6a8ab;
      border-radius: 5px;
      width: 80%;
      display: inline-block;
      margin-top: 1rem;
      margin-bottom: 1rem;
      @media (min-width: 996px) {
        margin:0 !important;
        width: 75%;
      }
    }
  }
}
