.near-city {
  
  @media (min-width: 992px) {
    
    margin-bottom: 7rem;
    
    
  }
  
  h4 {
    color: $metro-learn-more !important;
    font-size: 1.8rem;
    font-weight: 700;
    margin: 1.4rem 0 1.3rem 0;
  }
  
  p {
    color: $metro-text-dark;
  }
  
  .background {
    background-color: #6b4169;
  }
  
  img {
    opacity: 0.1;
  }
  
  .card-img-overlay {
    top: 59%;
    left: 48%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
    @media (min-width: 992px) {
      top: 62%;
      left: 48%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 1rem;
      font-weight: 700;
      
    }
  }
}