.thankyou_wrapper {
  i[class*="fa"] {
    font-size: 90px;
  }
  
  h1 {
    color: $green;
    font-size: 32px;
    margin: 30px 0 5px;
  }
  
  p {
    color: $dark-grey;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  
  @media (min-width: 768px) {
    i[class*="fa"] {
      font-size: 190px;
    }
    h1 {
      font-size: 54px;
    }
  }
}