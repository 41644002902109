.testimonial-section {
  padding-top: 3rem !important;
  @media(min-width: 992px) {
    padding-top: 1.5rem !important;
  }
  h3 {
    font-size: 1.75rem;
    font-weight: 700;
    @media(min-width: 992px) {
      font-size: 2rem;
    }
  }
  
  .testimonials-subtitle {
    color: $metro-subtitle;
    font-size: 1.2rem;
    @media(min-width: 992px) {
      font-size: 1.75rem;
    }
  }
  
  .testimonials-description {
    color: $metro-text-dark;
    line-height: 1.5;
    margin: 2.5rem auto 4rem;
  }
  
  .card-text {
    background-color: $metro-light-bg;
    padding: 2rem;
    border-radius: 0.5rem;
  }
  
  .more_text .read_more {
    display: none;
  }
}