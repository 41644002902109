.main-footer {
  
  .footer-logo {
    img {
      @media(max-width: 992px) {
        max-width: 300px
      }
    }
  }
  
  .social-icons {
    a {
      text-decoration: none;
      
      &:hover {
        filter: brightness(200%);
      }
    }
  }
  
  .footer-right-main {
    @media (min-width: 768px) {
      padding-left: 5rem;
    }
    
    .footer-menus {
      .widget_nav_menu {
        margin-bottom: 3rem;
      }
      
      ul {
        padding: 0;
        
        li {
          list-style: none;
          margin: 0 0 .625rem;
          
          a {
            color: $metro-text-dark;
            text-decoration: none;
            font-weight: 500;
            font-size: 1rem;
            
            &:hover {
              color: $metro-primary;
            }
          }
        }
      }
    }
  }
  
}
.metro-footer-bg-up{
  .subject{
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}
