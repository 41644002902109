.breadcrumbs {
  font-size: 1rem;
  padding: 30px 0;
  margin: 0;
  
  span a {
    color: $black !important;
    text-decoration: underline;
  }
  
  span.breadcrumb_last {
    color: $secondary !important;
    text-decoration: underline;
  }
  
  .fa-home {
    color: #930369;
  }
  
  a {
    color: #707070;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    font-weight: 600;
  }
  
  .current-item {
    color: #930369;
    padding-left: 0.5rem;
    font-weight: 600;
  }
  
  a[href$="/locations/"] {
    pointer-events: none;
  }
  
  a[href$="/services/"] {
    pointer-events: none;
  }
}
