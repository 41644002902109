.tooltip {
	top:  6.25px !important;
	left: 12.5px !important;
	opacity: 1 !important;
	.tooltip-inner {
		color: $metro-title !important;
		font-weight: bold;
		background-color: #fff;
		border-radius: 0.5rem;
		padding: 0.25rem 1rem;
	}
	.arrow:before {
		border-top-color: #fff !important;
		border-top-width: 8px;
	}
}