.location-archive-section{
  .location-archive-main{
      margin: 2rem 0;
    
    a{
      text-decoration: none;
    }
       img{
      border-radius: 0.3rem;
      margin-bottom: 1.5rem;
    }
    h3{
      color: $metro-title;
    }
    p{
      
        color: $metro-learn-more;
        text-decoration: none;
        font-weight: 500;
      
    }
  }
  .employee-img-archive{
    bottom: -8rem;
    right: 1rem;
    width: 70%;
  }
}