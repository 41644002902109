.krafts-section {
  background-color: #4a9e00;
  .bg-gradiant {
    /*background-color: #4a9e00;*/
    padding-top: 3rem;
    padding-bottom: 3rem;
    @media(min-width: 1200px) {
      padding-top: 7rem;
      padding-bottom: 7rem;
    }
  }

  .container-left-half {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    @media(min-width: 576px) {
      max-width: calc(540px - 30px);
    }
    @media(min-width: 768px) {
      //max-width: calc(360px - 30px); //half width
      max-width: calc(720px - 30px); //full width
    }
    @media(min-width: 992px) {
      margin-right: 0;
      max-width: calc(480px - 30px);
    }
    @media(min-width: 1200px) {
      max-width: calc(570px - 30px);
    }
  }

  h3 {
    color: $white;
    font-size: 2rem;
    font-weight: 800;
  }

  p {
    color: $white;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  a {
    background-color: #801a88;
    border: 0;
    font-weight: bold;
    &:hover {
      background-color: $metro-learn-more;
    }
  }

  .bg-image {
    background-position: center left;
    background-size: cover;
    background-repeat: no-repeat;
    height: 510px;
    @media(min-width: 768px) {
      height: auto;
    }
    img {
      margin: 0 auto;
      position: absolute;
      top: 35%;
      left: 0;
      right: 0;
      height: 30%;
      width: auto;
      max-width: none;
      @media(min-width: 2400px) {
        top: 30%;
        height: 40%;
      }
    }
  }
}