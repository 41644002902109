.services-sidebar {
  margin: 90px 0;
  padding-bottom: 15px;
  border-right: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    margin-left: 15px;
    
    &:before {
      font-family: "Font Awesome 5 Pro";
      content: "\f0da";
      margin-right: 3px;
    }
    
    a {
      color: $dark-grey;
      
      &:hover {
        text-decoration: underline;
      }
      
      &[aria-current="page"] {
        color: $primary;
      }
    }
  }
}