.blog-sidebar {
  background-color: $light-grey;
  padding: 1.5rem 2rem;
  border-radius: .75rem;
  
  h4 {
    font-weight: 600;
  }
}

.blog-left {
  background: #e8e8e8;
  padding: 10px 28px;
  border-radius: .75rem;
  
  h3 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  ul {
    margin: 0px;
    padding: 0;
    overflow: hidden;
    
    li {
      overflow: hidden;
      font-size: 13px;
      margin-bottom: 20px;
      border-bottom: 1px dashed #ddd;
      padding-bottom: 20px;
      
      &:last-child {
        border: none;
      }
    }
    
  }
  
  .post-categories {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline;
    
    li {
      margin: 0;
      padding: 0;
      display: inline;
    }
  }
  
  .sidebar-thumb {
    float: left;
    overflow: hidden;
    margin-right: 15px;
    
    img {
      height: auto;
      width: 75px;
      margin: 0.5rem 0 2rem;
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;
      border-radius: 0.5rem;
    }
    
  }
  
  .sidebar-content {
    h5 {
      font-size: 16px;
      cursor: pointer;
      line-height: 24px;
    }
    
    a {
      color: #858585;
      outline: 0 none;
      text-decoration: none;
      font-weight: bold
    }
    
    a:hover {
      color: #2996bd;
    }
  }
  
  .sidebar-meta {
    span {
      color: #2e2e2e;
    }
    
    .time {
      margin-right: 10px;
    }
    
    .author {
      color: #407bdf;
    }
  }
  
  
}