.fa, .fab, fal {
	vertical-align: middle;
}

.fa-2x:after {
	transform: scale(1.1);
}

.fa:after,
.fab:after,
.fal:after {
	display: inline-block;
	content: " ";
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	height: 25px;
	width: 25px;
}
.fa-facebook-f:after,
.fa-facebook:after {
	background-image: url("#{$img-path}/facebook-icon.png");
}

.fa-instagram:after {
	background-image: url("#{$img-path}/instagram-icon.png");
}

.fa-linkedin-in:after,
.fa-linkedin:after {
	background-image: url("#{$img-path}/linkedin-icon.png");
}

.fa-youtube:after {
	background-image: url("#{$img-path}/youtube-icon.png");
}

.fa-twitter:after {
	background-image: url("#{$img-path}/twitter-icon.png");
}

.fa-map-marker-alt:after {
	background-image: url("#{$img-path}/find-near-me.png");
	background-size: contain;
	width: 18px;
	height: 18px;
}