.entry-title {
  color: $secondary;
}

.entry-content {
  ul {
    list-style-type: none;
    line-height: 2rem;
    padding: 0;
    //padding-left: 1.8rem;
    
    li {
      position: relative;
      margin-left: 2rem;
      
      &:before {
        content: "";
        background-image: url("#{$img-path}/green-tick.png");
        background-repeat: no-repeat;
        background-position: center;
        min-width: 19px;
        height: 16px;
        position: absolute;
        top: 0.25rem;
        left: -2rem;
      }
      
      a {
        color: #858585;
        
        &:hover {
          color: #009394;
        }
      }
    }
    
  }
}

.container-left-half {
  //these padding and margin are to reflect original bootstrap container
  //padding-right: 15px;
  //padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (min-width: 576px) {
    //max-width for container at this width is 540px
    max-width: calc(540px - 30px);
  }
  @media (min-width: 768px) {
    margin-right: 0; //so the box aligns left;
    //max-width for container at this width is 720px
    max-width: calc(360px - 30px);
  }
  @media (min-width: 992px) {
    //max-width for container at this width is 960px
    max-width: calc(480px - 30px);
  }
  @media (min-width: 1200px) {
    //max-width for container at this width is 1140px
    max-width: calc(570px - 30px);
  }
}
.alert {
  background-color: #1c1c1c;
  font-size: .875rem
}

.close {
  font-size: 2.5rem;
  line-height: 0.7;
  color: #fff !important;
  font-weight: 100;
  cursor:pointer;
}

.alert a:hover {
  color: unset
}

.close:hover {
  color: #fff !important
}