#mauticform_wrapper_metropolitanplumbingblogpages .form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #80BDFF;
	outline: 0;
	box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
#mauticform_wrapper_metropolitanplumbingblogpages {
	background: none;
	box-shadow: none;
	padding: 0 0 3rem;
}
.bd-callout {
	padding: 1.5rem 2rem;
	margin-top: 2em;
	margin-bottom: 2rem;
	border: 1px solid #F7CCE7;
	border-left-color: rgb(238, 238, 238);
	border-left-width: .25rem;
	border-radius: .25rem;
	color: #212529;
}
.bd-callout-warning {
	border-left-color: #AA076B;
}
.bd-callout h3 {
	margin-top: 0rem !important;
	font-size: 1.75em !important;
}
.bookblog .btn-success {
	margin-top: 0.25rem;
}
.blog-form h2 {
	color: #C3007A !important;
	margin-top: 2.5rem !important;
	margin-bottom: 0.75rem !important;
}
.blog-form p {
	color: #212529;
}
.bookblog .btn-success {
	border: 2px solid #0DBEBF;
	background-color: #0DBEBF;
	font-size: 1rem;
	font-weight: 700;
	padding: .75rem 2.75rem;
	vertical-align: middle;
}
.bookblog .btn-success:active, .bookblog .btn-success:focus, .bookblog .btn-success:hover {
	background-color: #0DA2A3 !important;
	box-shadow: none !important;
	border-color: #0DA2A3 !important;
}