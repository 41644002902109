// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.


.three-points-inner {
  .col-lg-4 {
    @media (min-width: 992px) {
      .media-body {
        border-right: 1px solid $white;
      }
      &:last-child {
        .media-body {
          border-right: none;
        }
      }
    }
    
  }
  
  .media {
    padding: 5% 0%;
    
    @media (min-width: 768px) {
      padding: 4% 0%;
    }
    @media (min-width: 992px) {
      padding: 13% 0%;
    }
    
    .icon {
      img {
        
        width: 70%;
        @media (min-width: 992px) {
          width: 100%;
        }
      }
      
      .fal {
        color: $white;
        font-size: 30px;
        height: 30px;
        width: 30px;
        text-align: center;
        @media (min-width: 992px) {
          width: 40px;
          height: 40px;
          font-size: 40px;
        }
      }
    }
    
    .media-body {
      padding: 0px 5px;
      white-space: nowrap;
      
      @media (min-width: 992px) { //border-right: 1px solid $white;
        padding: 15px 20px;
      }
      
      strong {
        color: $white;
        font-size: 1rem;
      }
      
      span {
        color: $yellow;
        font-size: 1.5rem;
        font-weight: bold;
        font-style: italic;
      }
    }
    
  }
  
}
