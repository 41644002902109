.btn {
  color: $white;
  border-radius: 0.25rem;
  border: 2px solid $white;
  background-color: transparent;
  transition: background-color 1s ease;
  padding: 5px 10px;
  @media (min-width: 768px) {
    padding: 10px 20px;
  }
  @media (min-width: 1200px) {
    padding: 15px 30px;
  }
  
  &:hover {
    background-color: $tertiary;
    color: $white;
  }
}

.btn-success {
  border: 2px solid $green;
  background-color: $green;
  font-size: 1rem;
  font-weight: 700;
  padding: 1.25rem 4rem;
  text-transform: capitalize;
  
  &:hover {
    background-color: $dark-green;
  }
}

.btn-primary {
  border: 2px solid $primary;
  background-color: $primary;
  font-size: 1.3rem;
  font-weight: 700;
  
  &:hover,&:focus {
    background-color: $dark-green !important;
    box-shadow: none !important;
    border-color: $dark-green !important;
  }
}

.hero-text .btn-success {
  border: 2px solid $green;
  background-color: $green;
  font-size: 1rem;
  font-weight: 700;
  padding: 1.25rem 4rem;
  
  &:hover {
    background-color: $dark-green;
  }
}

.btn-brands {
  padding: 0;
  border: none !important;
  background-color: #fff;
  color: #555;
  border-radius: 0;
  
  &:hover, &:active, &:focus {
    box-shadow: none;
    border: 0;
    background-color: #fff !important;
    padding: 0;
    color: #297cb7 !important;
  }
}

.btn-book {
  padding: 15px 30px;
  background-color: $red;
  display: inline-block;
  line-height: 1.2;
  text-align: center;
  border-radius: 3px;
  position: relative;
  
  @media (min-width: 768px) {
    padding: 15px 60px;
  }
  
  span {
    &:first-child {
      font-weight: 600;
      font-size: 12px;
      @media (min-width: 768px) {
        font-size: 18px;
      }
      
    }
    
    &:last-child {
      font-size: 10px;
      @media (min-width: 768px) {
        font-size: 12px;
      }
    }
  }
  
  .btn-arrow {
    position: absolute;
    height: 60px;
    width: auto;
    top: -15px;
    right: -30px;
    @media (min-width: 992px) {
      height: 75px;
    }
  }
  
}

.cta-box {
  border-radius: 2px;
  background-image: linear-gradient(to bottom right, #09275d 33%, #365792 50%, #09275d 66%);
  color: $white;
  width: max-content;
  padding: 15px;
  @media (min-width: 576px) {
    padding: 30px;
  }
  @media (min-width: 768px) {
    padding: 45px;
  }
  @media (min-width: 992px) {
    padding: 60px;
  }
  
  h5 {
    font-size: 26px;
  }
  
  h1 {
    font-size: 42px;
  }
}

.btn-small {
  padding: 7px 18px;
  font-size: 12px;
  
  @media (min-width: 992px) {
    font-size: 16px;
  }
}

.nav-bookcall {
  .btn-success {
    border: 2px solid $metro-primary;
    background-color: $metro-primary;
    font-size: 1rem;
    font-weight: 700;
    padding: 0.5rem 0.9rem;
    vertical-align: middle;
    @media (min-width: 420px) {
      //font-size: 0.82rem;
    }
    @media (min-width: 576px) and (max-width: 992px) {
    margin-top: -5px;
      margin-bottom: 5px;
      border-radius: 0 0 0.3rem 0.3rem;
    }
    @media(min-width: 768px) {
      padding: 0.5rem 0.75rem;
    }
    @media(min-width: 1200px) {
      padding: 0.75rem 2.75rem;
    }
    
    &:hover,&:focus,&:active {
      background-color: $dark-green !important;
      box-shadow: none !important;
      border-color: $dark-green !important;
      @media (min-width: 420px) {
        //font-size: 0.875rem;
      }
      @media(min-width: 1200px) {
        //font-size: 0.875rem;
      }
    }
  }
}

.btn-more {
  border: 2px solid $tertiary;
  color: white;
  font-weight: 600;
  padding: 0.85rem 2rem;
  background-color: $metro-learn-more;
  border-radius: 0.3rem;
  
  &:focus {
    box-shadow: none;
  }
}