.opening-hours {
  padding-top: 1rem;
  padding-bottom: 4rem;
  
  .title {
    color: #323232;
    font-weight: 600;
  }
  
  .state {
    font-weight: 400;
    color: #8c8c8c;
  }
  
  .state-desc {
    font-size: 1rem;
  }
  
  .hours-section {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    
    .title {
      font-size: 1rem;
      text-decoration: underline;
      color: #323232;
      padding: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    
    .hours {
      color: #17479d;
      font-weight: 600;
      padding-left: 2rem;
    }
  }
  
  .hours-male {
    width: 30%;
    bottom: 0;
    right: 0;
    @media (min-width: 768px) {
      width: 40%;
    }
    @media (min-width: 996px) {
      width: 50%;
    }
    
  }
}
